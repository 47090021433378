import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import { use100vh } from 'react-div-100vh'
import { imageUrlFor } from '../lib/image-url'
import { initGA, sendGAEvent, sendGAPageView } from './google-analytics'
import { initGA4, sendGA4Event, sendGA4PageView } from './google-analytics-4'
import { navbarHeight, footerMinHeight } from '../variables'

export function getSafeColumn(column) {
  return [1, 2, 3, 4, 6, 12].includes(column) ? `is-${column}` : ''
}

export function dummyTouchListeners() {}

// warp c switch
export function isWarpC() {
  return process.env.GATSBY_WARP_SITE === 'composers'
}

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function addColon(content) {
  return `${content}:`
}

// export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
//   return !isFuture(publishedAt)
// }
export function filterOutHiddenDocs({ isVisible }) {
  return isVisible
}

export function validUrl(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

export function getArtistUrl(slug) {
  const artistsRoute = isWarpC() ? 'composers' : 'artists'
  return `/${artistsRoute}/${slug.current || slug}/`
}

export function getProjectUrl(slug) {
  return `/projects/${slug.current || slug}/`
}

export function getPrevNodeSlug(slug, nodes) {
  const slugs = nodes.map(node => {
    return node.slug.current
  })
  const currentSlugStr = slug.current
  const currentIndex = slugs.indexOf(currentSlugStr)
  return nodes[currentIndex - 1]
    ? nodes[currentIndex - 1].slug.current
    : nodes[nodes.length - 1].slug.current
}

export function getNextNodeSlug(slug, nodes) {
  const slugs = nodes.map(node => {
    return node.slug.current
  })
  const currentSlugStr = slug.current
  const currentIndex = slugs.indexOf(currentSlugStr)
  return nodes[currentIndex + 1] ? nodes[currentIndex + 1].slug.current : nodes[0].slug.current
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

// dom
export function vhMinusNav() {
  const vh = use100vh()
  return vh ? `${vh - navbarHeight}px` : `calc(100vh - ${navbarHeight}px)`
}

export function vhMinusNavAndFooter() {
  const vh = use100vh()
  return vh
    ? `${vh - navbarHeight - footerMinHeight}px`
    : `calc(100vh - ${navbarHeight} - ${footerMinHeight})`
}

export function getWindowDimensions() {
  if (isBrowser()) {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }
}

// image
export function getFluidProps(image, maxWidth) {
  return getGatsbyImageData(image.asset._id, { maxWidth: maxWidth }, clientConfig.sanity)
}

export function buildSrcSetUrl(width) {
  return imageUrlFor(buildImageObj(image))
    .auto('format')
    .width(width)
    .height()
    .quality(_quality)
    .url()
}

export function buildSrcSetStr(image, widths = []) {
  const arr = widths.map(width => {
    const src = imageUrlFor(buildImageObj(image))
      .auto('format')
      .width(width)
      .height()
      .url()
    return `${src} ${width}w`
  })
  return arr.join(', ')
}

export function buildPlaceholderUrl(image, width) {
  return imageUrlFor(buildImageObj(image))
    .auto('format')
    .width(width)
    .height()
    .quality(1)
    .url()
}

export function stringifyHTML(content) {
  return 'a'
}

// browser
export function isBrowser() {
  return typeof window !== 'undefined'
}

export function firstLetterUppercased(content) {
  return content.charAt(0).toUpperCase()
}

export function setDiscoEmbedUrl(url, theme=isWarpC() ? 'dark' : 'light') {
  const urlObject = new URL(url)
  const params = urlObject.searchParams
  params.set("theme", theme)
  urlObject.search = params.toString();
  return urlObject.href
}

export function setSpotifyEmbedUrl(url) {
  const urlObject = new URL(url)
  const params = urlObject.searchParams
  params.set("theme", 0)
  urlObject.search = params.toString();
  return urlObject.href
  // const preThemedStripped = url.substring(0, url.indexOf('&theme='))
  // if (preThemedStripped.length) {
  //   return preThemedStripped.concat('&theme=0')
  // } else {
  //   return url + `&theme=0`
  // }
}

export function inheritClassNames(data) {
  const className = data.props?.className || data.className || ''
  return className
}

export function inheritProps(props, attributes) {
  let collector = {}
  attributes.forEach(attribute => {
    if (props[attribute]) {
      collector[attribute] = props[attribute]
    }
  })
  return collector
}

export function getKebab(camel) {
  return camel
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter
    })
    .join('')
}

// analytics
export function initAnalytics() {
  if (process.env.GATSBY_IS_TRACKING_ENABLED === 'true') {
    if (isWarpC()) {
      initGA4()
    } else {
      initGA()
    }
  }
}

export function sendAnalyticsPageView(pathname) {
  if (process.env.GATSBY_IS_TRACKING_ENABLED === 'true') {
    if (isWarpC()) {
      sendGA4PageView()
    } else {
      sendGAPageView(pathname)
    }
  }
}

export function trackArtistDownloadLink(category, action, label) {
  if (process.env.GATSBY_IS_TRACKING_ENABLED === 'true') {
    if (isWarpC()) {
      sendGA4Event(category, action, label)
    } else {
      sendGAEvent(category, action, label)
    }
  }
}
