const React = require('react')
const LayoutContainer = require('./src/containers/layout').default
const { pageTransitionDuration } = require('./src/components/page-transition')

exports.wrapPageElement = ({ element, props }) => {
  return <LayoutContainer {...props}>{element}</LayoutContainer>
}

exports.onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[locations.length - 2]
}

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  let {justMounted} = document.body.dataset
  justMounted = Boolean(justMounted)
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), pageTransitionDuration + 15)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), justMounted ? 0 : pageTransitionDuration + 15)
  }
  return false
}
