import React from 'react'
import '../styles/styles.scss'
import Nav from './nav'
import Footer from './footer'
import { getCookieConsentValue } from 'react-cookie-consent'
import { initAnalytics, isBrowser, isWarpC, sendAnalyticsPageView } from '../lib/helpers'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import PageTransition, { pageTransitionDuration } from './page-transition'
import { isChrome, isIOS } from 'react-device-detect'
import ContentWrapper from './content-wrapper'
import scrollToElement from 'scroll-to-element'
import CookieBar from './cookie-bar'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      isCookieBarFaded: false,
      isTouching: true,
      transitionKey: null,
    }
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
    this.cookieBarContainerRef = React.createRef()
    this.lastTouchTime = 0
  }

  onPageChange = () => {
    const pathname = this.props.location?.pathname
    if (pathname !== null && !['/license/request-license'].includes(pathname)) {
      if (this.state.pathname !== pathname) {
        this.setState({
          pathname: pathname,
        })
      }
    }
    if (pathname && getCookieConsentValue() === 'true') {
      sendAnalyticsPageView(pathname)
    }
  }

  showCookieBar() {
    this.state.isCookieBarFaded && this.setState({ isCookieBarFaded: false })
  }

  fadeCookieBar() {
    !this.state.isCookieBarFaded && this.setState({ isCookieBarFaded: true })
  }

  checkForCookieBar() {
    const containerEl = this.cookieBarContainerRef.current
    if (containerEl && containerEl.childNodes && containerEl.childNodes.item(0)) {
      this.showCookieBar()
    } else {
      this.fadeCookieBar()
    }
  }

  onCookieAccept = () => {
    initAnalytics()
    const pathname = this.props.location?.pathname
    pathname && sendAnalyticsPageView(pathname)
    this.fadeCookieBar()
  }

  handleTouchStart() {
    this.lastTouchTime = new Date()
    this.setState({ isTouching: true })
  }

  handleMouseMove(e) {
    if (new Date() - this.lastTouchTime > 500 && this.state.isTouching) {
      this.setState({ isTouching: false })
    }
    if (e.clientY <= 10) {
      document.body.classList.add('is-mouse-above-viewport')
    } else {
      document.body.classList.remove('is-mouse-above-viewport')
    }
  }

  render() {
    const { children, siteTitle, headerItems, footerItems, news, path, location } = this.props
    const showNav = path !== '/license/request-license/'
    const isAr = path.startsWith("/ar/")
    const pathname = location && location.pathname
    const { scrolled, isTouching, isCookieBarFaded } = this.state
    const htmlClasses = classNames({
      'is-warp-c': isWarpC(),
      'is-cookie-bar-visible': !isCookieBarFaded,
    })
    const bodyClasses = classNames({
      'is-touching': isTouching,
      'is-chrome': isChrome,
      'is-ios': isIOS,
    })
    return (
      <>
        <Helmet>
          <html className={htmlClasses} />
          <body className={bodyClasses} />
        </Helmet>
        <PageTransition transitionKey={showNav ? 'show-nav' : 'hide-nav'} element="nav">
          {showNav && (
            <Nav
              headerItems={headerItems}
              siteTitle={siteTitle}
              scrolled={scrolled}
              path={pathname}
              isAr={isAr}
            />
          )}
        </PageTransition>
        <ContentWrapper pathname={pathname}>
          <PageTransition
            transitionKey={location.pathname}
            element={'div'}
            innerClassName="is-flex grow"
          >
            <main>{children}</main>
          </PageTransition>
        </ContentWrapper>
        <PageTransition transitionKey={location.pathname} element="footer">
          {showNav && <Footer footerItems={footerItems} siteTitle={siteTitle} news={news} />}
        </PageTransition>
        <div ref={this.cookieBarContainerRef}>
          <CookieBar
            onAccept={() => this.onCookieAccept()}
            onDecline={() => this.fadeCookieBar()}
          />
        </div>
      </>
    )
  }

  componentDidMount() {
    if (!isBrowser) {
      return
    }
    document.body.dataset.justMounted = true
    setTimeout(() => {
      delete document.body.dataset.justMounted
    }, pageTransitionDuration)
    this.checkForCookieBar()
    getCookieConsentValue() === 'true' && initAnalytics()
    window.addEventListener('touchstart', this.handleTouchStart)
    window.addEventListener('mousemove', this.handleMouseMove)
    this.onPageChange()
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.checkForCookieBar()
    if (prevState.pathname !== this.state.pathname) {
      const hash = window.location.hash
      if (hash) {
        setTimeout(() => {
          scrollToElement(hash, {
            offset: 64,
            duration: 600,
          })
        }, 400)
      }
    }
    this.onPageChange()
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.handleTouchStart)
    window.removeEventListener('mousemove', this.handleMouseMove)
  }
}

export default Layout
