import React, { useEffect, useRef, useState } from 'react'
export const pageTransitionDuration = 180

function PageTransition(props) {
  const { children, innerClassName="", className="", transitionKey, element = 'div' } = props
  const [visible, setVisible] = useState(false)
  const [currentContent, setCurrentContent] = useState(children)
  const savedScroll = useRef(null)
  const isMounted = useRef(false)

  function handlePopstate() {
    savedScroll.current = window.pageYOffset
  }

  useEffect(() => {
    if (isMounted.current) {
      if (savedScroll.current) {
        window.scrollTo(0, savedScroll.current)
        savedScroll.current = null
      }
      setVisible(false)
      setTimeout(() => {
        setVisible(true)
      }, pageTransitionDuration + 40)
    } else {
      setVisible(true)
      isMounted.current = true
    }
  }, [transitionKey])

  useEffect(() => {
    setTimeout(() => {
      setCurrentContent(children)
    }, pageTransitionDuration)
  }, [children])

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate)
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  return React.createElement(
    element,
    {
      className: `${className} page-transition`,
    },
    <div
      className={innerClassName}
      style={{
        opacity: visible ? 1 : 0,
        transition: `opacity ${pageTransitionDuration}ms ease-in`,
      }}
    >
      {currentContent}
    </div>
  )
}

export default PageTransition
