import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'

const query = graphql`
  query LayoutQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
    navMenu: sanityNavigationMenu(title: {eq: "Main"}) {
      headerItems {
        route
        title
        link
      }
      footerItems: _rawFooterItems
    }
  }
`

function LayoutContainer(props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <Layout
            {...props}
            siteTitle={data.site.title}
            headerItems={data.navMenu.headerItems}
            footerItems={data.navMenu.footerItems}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
