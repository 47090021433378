import React, { useState } from 'react'
import cx from 'classnames'

const handleFormSubmit = e => {
  e.preventDefault()
}

function NewsletterSignupBlock(props) {
  const [newsletterConsent, setNewsletterConsent] = useState(false)
  return (
    <form
      onSubmit={handleFormSubmit}
      action=""
      method="POST"
      className={cx('newsletter-signup-block', { 'is-consent-checked': newsletterConsent })}
      name="newsletter-signup"
    >
      <input
        type="email"
        className="newsletter-signup-block__input-wrapper__email text-l mob-pl-1_2 mob-pr-1_2"
        name="email"
        placeholder="email"
      />
      <button
        onClick={() => setNewsletterConsent(!newsletterConsent)}
        className="newsletter-signup-block__input-wrapper__consent mob-pl-1_2 mob-pr-1_2"
        type="checkbox"
      >
        <label htmlFor="newsletter-signup">I agree to be contacted by email</label>
      </button>
      <button
        className="sign-up-button newsletter-signup-block__input-wrapper__submit text-caps mob-pl-1_2 mob-pr-1_2"
        type="submit"
      >
        Sign-up
      </button>
    </form>
  )
}

export default NewsletterSignupBlock
