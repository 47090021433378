import React from 'react'
import { vhMinusNavAndFooter } from '../lib/helpers'

const ContentWrapper = ({ children, pathname }) => {
  // offset for sync filter height change
  const style =
    pathname && pathname !== '/sync' ? { minHeight: vhMinusNavAndFooter(), maxHeight: `100%` } : {}
  return (
    <div className="content-wrapper" id="content-wrapper" style={style}>
      {children}
    </div>
  )
}

export default ContentWrapper
