import React, { useEffect, useCallback } from 'react'
import CTALink from './CTALink'
import { format } from 'date-fns'
import NewsletterSignupBlock from './newsletter-singup-block'
import AnimateHeight from 'react-animate-height'
import { useClickAway, useKey } from 'react-use'

function Footer(props) {
  const { siteTitle, footerItems = [] } = props
  const [activeKey, setActiveKey] = React.useState(null)
  const [footerMenuActive, setFooterMenuActive] = React.useState(false)
  const footerRef = React.useRef()

  function handleOnClickAway() {
    if (activeKey === null) {
      setFooterMenuActive(false)
    } else {
      setActiveKey(null)
    }
  }

  useClickAway(footerRef, handleOnClickAway, ['click'])
  useClickAway(footerRef, handleOnClickAway, ['touch'])

  const handleOnClick = key => {
    if (activeKey === key) {
      setActiveKey(null)
      setFooterMenuActive(false)
    } else {
      setActiveKey(key)
    }
  }

  const footerItemsElements = (
    <>
      {footerItems.map(link => {
        switch (link._type) {
          case 'cta':
            return (
              <CTALink
                className="text-caps tab-ml-1_2"
                onClick={() => handleOnClick(null)}
                key={link._key}
                {...link}
              />
            )
          case 'newsletterSignupBlock':
          case 'ctaRollUp':
            return (
              <button
                className="text-caps tab-ml-1_2"
                key={link._key}
                onClick={() => handleOnClick(link._key)}
              >
                {link.title}
              </button>
            )
        }
      })}
    </>
  )

  return (
    <div ref={footerRef}>
      <div className="footer__roll-up">
        {footerItems
          .filter(item => ['newsletterSignupBlock', 'ctaRollUp'].includes(item._type))
          .map(item => {
            {
              let el
              switch (item._type) {
                case 'newsletterSignupBlock':
                  el = <NewsletterSignupBlock title={item.title} />
                  break
                case 'ctaRollUp':
                  el = (
                    <ul
                      className="text-caps footer-roll-up__links"
                      key={item._key}
                    >
                      {item.items.map((link, linkIndex) => (
                        <li key={link._key} className="mob-pl-1 tab-pl-1_2">
                          <a href={link.url} target="_blank">
                            {link.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )
                  break
              }
              return (
                <AnimateHeight
                  height={activeKey === item._key ? 'auto' : 0}
                  key={item._key}
                  duration={300}
                  className="footer-roll-up"
                  onAnimationEnd={() => {
                    if (!activeKey) {
                      setFooterMenuActive(false)
                    }
                  }}
                >
                  {el}
                </AnimateHeight>
              )
            }
          })}
        <AnimateHeight className="none-from-tablet" height={footerMenuActive ? 'auto' : 0}>
          <div className="footer-items mob-p-1 tab-p-1_2 border-top-single">
            {footerItemsElements}
          </div>
        </AnimateHeight>
      </div>
      <div className="footer-items none-until-tablet border-top-single">
        {footerItemsElements}
        <div className="text-caps mob-pr-1_2">
          © {format(new Date(), 'YYYY')} {siteTitle}
        </div>
      </div>
      <div className="none-from-tablet footer-mobile-button border-top-single">
        <button
          className="text-caps mob-ml-1 tab-ml-1_2"
          onClick={() => {
            if (!footerMenuActive) {
              setFooterMenuActive(true)
            } else {
              handleOnClickAway()
            }
          }}
        >
          information
        </button>
        <div className="mob-mr-1 tab-mr-1_2 footer__copyright text-caps">
          © {format(new Date(), 'YYYY')} {siteTitle}
        </div>
      </div>
    </div>
  )
}

export default Footer
