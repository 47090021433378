import React, { useRef, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { pageTransitionDuration } from './page-transition'


function DelayLink(props) {
  const { to, className, children, callbacks } = props
  const linkRef = useRef()

  function handleOnClick(e) {
    e.preventDefault()
    setTimeout(() => {
        navigate(to)
    }, pageTransitionDuration)
  }
  
  return (
    <Link ref={linkRef} to={to} className={className} {...callbacks} onClick={handleOnClick}>
      {children}
    </Link>
  )
}

export default DelayLink
