import { Link } from 'gatsby'
import React, { useRef, useState, useEffect } from 'react'
import CTALink from './CTALink'
import Logo from '../images/logo/warp-logo.svg'
import classNames from 'classnames'
import Hamburger from './icon/hamburger'
import { Helmet } from 'react-helmet'
import { isWarpC, isBrowser } from '../lib/helpers'
import { breakpoints } from '../variables'
import disableScroll from 'disable-scroll'
import { pageTransitionDuration } from './page-transition'

const Nav = props => {
  const { headerItems = [], path, isAr = false } = props
  const [isMobileOverlayActive, _setIsMobileOverlayActive] = useState(false)
  function setIsMobileOverlayActive(to, value) {
    if (value === true || to === path || !to) {
      _setIsMobileOverlayActive(value)
    } else {
      setTimeout(() => {
        _setIsMobileOverlayActive(value)
      }, pageTransitionDuration + 10)
    }
  }
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  // const [topScrollOffset, setTopScrollOffset] = useState(isBrowser() ? window.scrollY < 40 : 0)
  const [topScrollOffset, setTopScrollOffset] = useState(true)

  const navRef = useRef()
  const navSectionsRef = useRef()
  const hamburgerRef = useRef()

  let lastScrollPosition = isBrowser() && window.scrollY
  let lastScrollDownPosition = isBrowser() && window.scrollY

  const getLiClass = (route, path) => {
    const artistsRoute = isWarpC() ? '/composers' : '/artists'
    const isArtistSection = route === artistsRoute && `/${path.split('/')[1]}` === artistsRoute
    const isProjectSection = route === '/sync' && `/${path.split('/')[1]}` === '/projects'
    if (route === path) {
      return true
    } else if (isArtistSection) {
      return true
    } else if (isProjectSection) {
      return true
    }
  }

  const handleScroll = () => {
    if (window.scrollY < 40) {
      setTopScrollOffset(true)
    } else {
      setTopScrollOffset(false)
    }
    if (!isMobileOverlayActive) {
      if (window.scrollY <= lastScrollPosition) {
        if (window.scrollY <= lastScrollDownPosition - 50) {
          setIsScrollingUp(true)
          document.body.classList.add('is-scrolling-up')
        }
      } else {
        lastScrollDownPosition = window.scrollY
        setIsScrollingUp(false)
        document.body.classList.remove('is-scrolling-up')
      }
      lastScrollPosition = window.scrollY
    }
  }

  const toggleMobileNav = () => {
    setIsMobileOverlayActive(null, !isMobileOverlayActive)
  }

  useEffect(() => {
    if (!isAr) {
      const els = navSectionsRef.current.querySelectorAll('li')
      els.forEach(el => {
        const route = el.dataset.route
        getLiClass(route, path) && el.classList.add('is-in')
      })
    }
  }, [isAr])

  useEffect(() => {
    setTopScrollOffset(window.scrollY < 40)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!isAr) {
      if (isMobileOverlayActive) {
        disableScroll.on()
        hamburgerRef.current.cross()
      } else if (!isMobileOverlayActive) {
        hamburgerRef.current.hamburger()
        disableScroll.off()
      }
    }
  }, [isAr, isMobileOverlayActive])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > breakpoints.tablet) {
        setIsMobileOverlayActive(null, false)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const classes = classNames({
    'is-home': path === '/',
    'is-mobile-nav-overlay-active': isMobileOverlayActive,
    'is-scrolling-up': isScrollingUp,
    'top-scroll-offset': topScrollOffset,
  })

  const hamburgerClasses = classNames({
    'is-active': isMobileOverlayActive,
  })

  return (
    <>
      <Helmet>
        <body data-is-mobile-nav-active={isMobileOverlayActive || isScrollingUp} />
      </Helmet>
      <div
        ref={navRef}
        className={`nav text-caps ${classes} text-m`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="nav__inner columns no-border-top">
          <Link
            to="/"
            onClick={() => {
              setIsMobileOverlayActive('/', false)
            }}
            className="nav__logo column is-6"
            aria-label="Main page"
          >
            <span className="nav__logo-svg">
              <Logo />
            </span>
            <span className="nav__logo-hover-title text-nav">
              {isWarpC() ? 'Warp Composers' : 'Warp Publishing'}
            </span>
          </Link>
          {!isAr && (
            <button
              onClick={toggleMobileNav}
              className="nav__mobile-toggle is-6"
              role="button"
              aria-label="menu"
              aria-expanded="false"
            >
              <Hamburger
                ref={hamburgerRef}
                className={hamburgerClasses}
                stroke={isWarpC() ? 'black' : path && path === '/' ? 'black' : 'white'}
              />
              <span className="menu-content">
                <span data-content="menu">menu</span>
                <span data-content="close">close</span>
              </span>
            </button>
          )}
          {isAr && (
            <ul
              className="text-nav column"
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <li style={{ color: 'white' }}>A&R</li>
            </ul>
          )}
          {headerItems && !isAr && (
            <div ref={navSectionsRef} className="nav__sections column is-6">
              <>
                <ul className="text-nav">
                  {headerItems.map((item, i) => {
                    console.log(item)
                    const route = item.route
                    const liClasses = classNames({
                      'is-in': getLiClass(route, path),
                    })
                    return (
                      <li
                        className={liClasses}
                        data-route={item.route}
                        data-ref="nav-li"
                        key={`nav_${item.route}_${i}`}
                      >
                        {/* <DelayLink to={}></DelayLink> */}
                        <CTALink
                          {...item}
                          onClick={() => setIsMobileOverlayActive(item.route, false)}
                        />
                      </li>
                    )
                  })}
                </ul>
                <div
                  className="nav__sections__filler"
                  onClick={() => setIsMobileOverlayActive(null, false)}
                ></div>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Nav
