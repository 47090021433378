// data
export const composerCategoryId = '-812f07de-6fae-5a18-931c-5ecda0b1b288'
export const warpCUrl = 'http://warpcomposers.net/'

// nav, footer
export const navbarHeight = 50
export const footerMinHeight = 51

// mq
export const tablet = 1024
export const desktop = 1400
export const widescreen = 1920
export const fullHd = 2400
export const breakpoints = {
  mobileXs: 320,
  mobile: 321,
  mobileLarge: 375,
  mobileXl: 550,
  tabletSmall: 800,
  tablet: tablet,
  tabletLarge: 1024,
  desktopSmall: 1280,
  desktop: desktop,
  widescreen: 1920,
  widescreenXl: fullHd,
}

// page transitions
export const pageTDuration = 0.2
export const pageTEase = [0.47, 0, 0.745, 0.715]
export const pageTMotionVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTDuration,
      delay: pageTDuration,
      when: 'beforeChildren',
      ease: pageTEase,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: pageTDuration, ease: pageTEase },
  },
}
