import React, { Component } from 'react'
import CookieConsent from 'react-cookie-consent'
import { isWarpC } from '../lib/helpers'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

function CookieBar(props) {
  return (
    <>
      <Helmet>
        <body data-testje="hi" />
      </Helmet>
      <CookieConsent
        cookieName={
          isWarpC() ? 'warp-publishing-cookie-selection' : 'warp-composers-cookie-selection'
        }
        location="bottom"
        buttonText={`Allow all`}
        ariaAcceptLabel={`Allow all cookies`}
        declineButtonText={`Only necessary cookies`}
        ariaDeclineLabel={`Only allow necessary cookies`}
        containerClasses={`cookie-consent ${props.isCookieBarFaded ? 'is-faded' : ''}`}
        expires={150}
        disableStyles
        disableButtonStyles
        enableDeclineButton
        onAccept={props.onAccept}
        onDecline={props.onDecline}
      >
        <div className="cookie-consent__info" id="cookie-consent-info">
          <span>
            This site uses cookies. For more information, see our{' '}
            <Link className="btn no-border-top" to="/privacy-policy">
              privacy policy
            </Link>
          </span>
        </div>
      </CookieConsent>
    </>
  )
}

export default CookieBar
