import ReactGA from 'react-ga4'

export function initGA4() {
  ReactGA.initialize(process.env.GATSBY_GA_TRACKING_ID)
  ReactGA.set({ anonymizeIp: true })
}

export function sendGA4PageView(pathname) {
  ReactGA.send({ hitType: 'pageview', page: pathname })
}

export function sendGA4Event(category, action, label) {
  ReactGA.initialize(process.env.GATSBY_GA_TRACKING_ID)
  ReactGA.set({ anonymizeIp: true })
  ReactGA.event({
    category,
    action,
    label,
    nonInteraction: false,
  })
}
