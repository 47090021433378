import React from 'react'
import { Link, navigate } from 'gatsby'
import { getProjectUrl, getArtistUrl } from '../lib/helpers'
import DelayLink from './delay-link'

// const doNavigate = target => {
//   if (!target || !target.length) {
//     return
//   }
//   const internal = /^\/(?!\/)/.test(target)
//   if (internal) {
//     navigate(target)
//   } else {
//     window.location = target
//   }
// }

const CTALink = props => {
  let link = props.route || props.link || '#'
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = props.landingPageRoute.slug.current
  } else if (props.projectRoute && props.projectRoute.slug && props.projectRoute.slug.current) {
    link = getProjectUrl(props.projectRoute.slug)
  } else if (props.artistRoute && props.artistRoute.slug && props.artistRoute.slug.current) {
    link = getArtistUrl(props.artistRoute.slug)
  }
  let callbacks = {}
  if (props.onClick) {
    callbacks.onClick = props.onClick
  }

  const title = props?.linkContent ? props.linkContent : props.title
  const className = props.className
  if (props.link) {
    return (
      <a
        className={className}
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        {...callbacks}
      >
        {title || props.children}
      </a>
    )
  } else if (props.landingPageRoute || props.projectRoute || props.artistRoute || props.route) {
    return (
      <Link className={className} to={link} {...callbacks}>
        {title || props.children}
      </Link>
    )
  } else {
    return (
      <button className={className} title={title} {...callbacks}>
        {title || props.children}
      </button>
    )
  }
}

export default CTALink
